import React, { useState } from 'react';
import { Box, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import ProductCard from './ProductCard';
import { products } from '../data/products';

const ProductSelector = ({ selectedProduct, onProductSelect }) => {
  const [selectedCountry, setSelectedCountry] = useState('GB');

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const filteredProducts = products.filter(product => product.country === selectedCountry);

  const handleProductSelect = (productId, price, currency) => {
    onProductSelect(productId, price, currency); // Pass price and currency along with product ID
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Select Incentive Product
      </Typography>

      {/* Country selection dropdown */}
      <FormControl variant="outlined" sx={{ marginBottom: 2 }}>
        <Select
          value={selectedCountry}
          onChange={handleCountryChange}
          label="Country"
        >
          <MenuItem value="GB">GB</MenuItem>
          <MenuItem value="US">US</MenuItem>
        </Select>
      </FormControl>

      <Grid container spacing={3}>
        {filteredProducts.map((product) => (
          <Grid item xs={12} sm={6} key={product.id}>
            <ProductCard
              {...product}
              selected={selectedProduct === product.id}
              onChange={() => handleProductSelect(product.id, product.price, product.currency)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductSelector;