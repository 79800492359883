import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { removeGoogleIntegration } from '../../../services/api/ApiService'; // Adjust the import path as needed

function GoogleOAuthRemoveRedirect() {
  const { integrationId } = useParams();
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const onboarding = searchParams.get('onboarding');

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;

    const fetchGoogleAuthUrl = async () => {
      try {
        const response = await removeGoogleIntegration(integrationId);
        if (response.status === 'success') {
          if (onboarding) {
            localStorage.removeItem('onboarding');
            navigate('/integrations/gmail/');
          } else {
            navigate('/integrations/');
          }
        }
      } catch (error) {
        console.error('Failed to fetch Google OAuth URL:', error);
      }
    };

    fetchGoogleAuthUrl();
  }, []);

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 4, alignItems: 'center' }}>
        <Typography variant="h4" component="h1">
          Redirecting to Google...
        </Typography>
        <CircularProgress />
      </Box>
    </Container>
  );
}

export default GoogleOAuthRemoveRedirect;
