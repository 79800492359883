import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useAuth } from '../../services/auth/AuthService';
import BackLink from '../../components/BackLink';
import CampaignHeader from './CampaignHeader';
import { getCampaignV2, updateUser } from '../../services/api/ApiService';
import CampaignStatistics from './CampaignStatistics';
import CampaignEmailTracking from './CampaignEmailTracking';
import OnboardingSteps from '../../components/Onboarding/OnboardingSteps';

const emptyCampaign = {
  id: '',
  name: '',
  status: '',
  created: '',
  metrics: {
    drafted: 0,
    sent: 0,
    replied: 0,
    rewards_earned: 0,
    rewards_redeemed: 0,
    rewards_missed: 0,
  },
  emails: [],
  incentives: [],
  incentive_mentioned: false,
  incentive_deadline: '',
  charge_on: '',
  payment_method: '',
};

const CampaignDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [campaignStatus, setCampaignStatus] = useState('');
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [isDraftsOpen, setIsDraftsOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [campaign, setCampaign] = useState(emptyCampaign);
  const { userDetails, isOnboarded, syncIsOnboarded } = useAuth();

  const [openCelebration, setOpenCelebration] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    try {
      const campaignData = await getCampaignV2(id);
      setCampaign(campaignData);
    } catch (error) {
      console.error('Failed to fetch drafts:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (isOnboarded) {
      setIsLoading(false);
      return;
    }
    if (campaignStatus === 'live') {
      setOnboardingStep(4);
      setOpenCelebration(true);
    } else if (campaignStatus === 'tracked') {
      setOnboardingStep(3);
      setIsDraftsOpen(false);
    } else if (campaignStatus === 'untracked') {
      setOnboardingStep(2);
      setIsDraftsOpen(true);
    } else {
      setOnboardingStep(1);
    }
    setIsLoading(false);
  }, [campaignStatus]);

  useEffect(() => {
    loadData().then(null);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleCloseCelebration = async () => {
    await setOpenCelebration(false);
    localStorage.removeItem('campaignData');  // Clear campaign data in local storage
    await updateUser(userDetails.id, {
      is_onboarded: true,
      onboarding_progress: userDetails.onboarding_progress,
    }).then(null);
    await syncIsOnboarded();
    window.location.reload();
  };

  return (
    <>
      {!isOnboarded && <OnboardingSteps activeStep={onboardingStep} displayText={false} />}

      {isLoading && <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>}
      {!isOnboarded ? <BackLink path="/billing" /> : <BackLink path="/campaigns" />}
      <CampaignHeader campaign={campaign} setCampaign={setCampaign} setCampaignStatus={setCampaignStatus} />
      <CampaignStatistics campaign={campaign} />

      {/* Tabs Section */}
      <AppBar position="static" color="default" elevation={0}>
        <Box flexDirection="row" display="flex" justifyContent="space-between" alignItems="center">
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Email Tracking" />
            {/*<Tab label="Settings" />*/}
          </Tabs>
          <Box flexDirection="row" display="flex" justifyContent="space-between" alignItems="center">
            <Button
              onClick={() => setIsDraftsOpen(!isDraftsOpen)}
              variant="contained"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faGoogle} />}
              sx={{ '&:hover': { backgroundColor: '#357ae8' }, mx: 2 }}
            >
              Add Draft
            </Button>
            <Button
              onClick={() => loadData()}
              variant="contained"
              color="secondary"
              sx={{ '&:hover': { backgroundColor: '#357ae8' }, mx: 2 }}
            >
              Refresh
            </Button>
          </Box>
        </Box>
      </AppBar>

      {tabIndex === 0 && (
        <CampaignEmailTracking campaign={campaign} isDraftsOpen={isDraftsOpen} setIsDraftsOpen={setIsDraftsOpen}
                               setCampaign={setCampaign} campaignStatus={campaignStatus}
                               setCampaignStatus={setCampaignStatus} />)}

      {/* Celebration Modal */}
      <Dialog open={openCelebration} onClose={() => handleCloseCelebration()}>
        <DialogTitle>Congratulations!</DialogTitle>
        <DialogContent>
          <p>You have successfully completed the onboarding process. 🎉</p>
          <p>Your campaign is now live and ready to reward recipients for replying to your emails.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseCelebration()} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignDetail;