import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Link, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useAuth } from '../../../services/auth/AuthService';
import { getIntegrations } from '../../../services/api/ApiService';
import OnboardingSteps from '../../../components/Onboarding/OnboardingSteps';
import { updateOnboardingStatus } from '../../../utils';

function GmailIntegration() {
  const navigate = useNavigate();
  const { userDetails, isOnboarded } = useAuth(); // Access userDetails via useAuth
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isIntegrated, setIsIntegrated] = useState(false);

  const fetchIntegrations = async () => {
    const integrations = await getIntegrations();
    const googleIntegration = integrations.data.find(
      (integration) => integration.type === 'gmail' && integration.status === 'active',
    );
    setIsIntegrated(!!googleIntegration);
  };

  useEffect(() => {
    if (isIntegrated) {
      updateOnboardingStatus('integration_connected', 2, userDetails).then(null);
    }
  }, [isIntegrated]);

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const handleAddIntegration = () => {
    navigate('/google/begin-integration?onboarding=true');
  };

  const handleRemoveIntegration = async () => {
    const integrations = await getIntegrations();
    const integrationId = integrations.data.find(
      (integration) => integration.type === 'gmail' && integration.status === 'active',
    ).id;
    navigate(`/google/remove-integration/${integrationId}?onboarding=true`);
  };

  const handleGoBack = () => {
    navigate('/campaigns/create');
  };

  const handleCompleteOnboarding = () => {
    navigate('/billing');
  };

  return (
    <Box sx={{ textAlign: 'center', p: 4 }}>
      <OnboardingSteps activeStep={1} displayText={false} />
      <Typography variant="h4" gutterBottom>
        Gmail Integration
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        {isIntegrated
          ? 'You have already integrated your Gmail account. If you wish to remove it, you can do so below.'
          : 'Connect your Gmail account to start using it with Speedable.'}
      </Typography>

      {/* Conditional rendering based on isOnboarded and isIntegrated */}
      {isIntegrated ? (
        // If integrated, show remove button
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleRemoveIntegration}
            sx={{
              minWidth: '200px',
              mb: 2, // Adding margin between buttons
            }}
          >
            Remove Gmail Integration
          </Button>
          {!isOnboarded && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
              <Button
                type="button"
                variant="outlined"
                size="large"
                component={Link}
                onClick={handleGoBack}
                sx={{ flexGrow: 1, mr: 2 }}
              >
                Back: Campaigns
              </Button>
              <Button
                variant="contained"
                onClick={handleCompleteOnboarding}
                type="submit"
                size="large"
                sx={{ flexGrow: 1 }}
              >
                Next: Add Credits
              </Button>
            </Box>
          )}
        </Box>
      ) : isOnboarded ? (
        // If onboarded but not integrated, show add button
        <Button
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faGoogle} />}
          onClick={handleAddIntegration}
          sx={{
            backgroundColor: '#1a73e8',
            color: 'white',
            minWidth: '200px',
            '&:hover': {
              backgroundColor: '#135ba1',
            },
          }}
        >
          Add Gmail Integration
        </Button>
      ) : (
        // If not onboarded and not integrated, show both the back and complete onboarding buttons
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Button
            type="button"
            variant="outlined"
            size="large"
            component={Link}
            onClick={handleGoBack}
            sx={{ flexGrow: 1, mr: 2 }}
          >
            Back: Campaigns
          </Button>
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faGoogle} />}
            onClick={handleAddIntegration}
            type="submit"
            size="large"
            sx={{ flexGrow: 1 }}
          >
            Add Gmail Integration
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default GmailIntegration;