import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WelcomeDialog from '../components/Onboarding/WelcomeDialog';
import OnboardingSteps from '../components/Onboarding/OnboardingSteps';
import { useAuth } from '../services/auth/AuthService';

function Dashboard() {
  const [showWelcome, setShowWelcome] = useState(false);
  const { userDetails, isOnboarded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOnboarded) {
      const onboardingProgress = userDetails?.onboarding_progress.steps;
      const maxIndex = onboardingProgress.reduce((acc, step) => {
        return step.completed === true ? Math.max(acc, step.index) : acc;
      }, 0);
      if (maxIndex < 1) {
        setShowWelcome(true);
      } else if (maxIndex === 1) {
        navigate('/integrations/gmail');
      } else if (maxIndex === 2) {
        navigate('/billing');
      } else if (maxIndex === 3) {
        navigate('/campaigns/create');
      } else if (maxIndex === 4) {
        navigate('/campaigns');
      }
    }
    const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
    if (!hasSeenWelcome) {
      setShowWelcome(true);
    }
  }, []);

  const handleWelcomeClose = () => {
    localStorage.setItem('hasSeenWelcome', 'true');
    setShowWelcome(false);
    navigate('/campaigns/create');
  };

  return (<Box sx={{ p: 4 }}>
    <Typography variant="h4" gutterBottom>
      Welcome, {userDetails?.email}!
    </Typography>

    <Box sx={{ my: 4 }}>
      <OnboardingSteps activeStep={0} />
    </Box>

    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => navigate('/campaigns/create')}
      >
        Create Your First Campaign
      </Button>
    </Box>

    <WelcomeDialog
      open={showWelcome}
      onClose={handleWelcomeClose}
    />
  </Box>);
}

export default Dashboard;