import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import { saveGoogleTokens } from '../../../services/api/ApiService';
import OnboardingSteps from '../../../components/Onboarding/OnboardingSteps';
import { updateOnboardingStatus } from '../../../utils';
import { useAuth } from '../../../services/auth/AuthService';

function GoogleOAuthRedirect() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useAuth();
  const [status, setStatus] = useState({ state: 'loading', message: '' });
  const isProcessing = useRef(false);

  useEffect(() => {
    if (isProcessing.current) return;
    isProcessing.current = true;

    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const error = params.get('error');

    if (error || !code) {
      console.error('Google OAuth error:', error);
      setStatus({ state: 'error', message: 'Failed to authenticate with Google. Please try again.' });
      return;
    }

    const saveTokens = async () => {
      try {
        const resp = await saveGoogleTokens(code);
        setStatus({ state: 'success', message: resp.message });
        await updateOnboardingStatus('integration_connected', 2, userDetails);
      } catch (error) {
        setStatus({ state: 'error', message: 'Failed to save Google OAuth tokens. Please try again.' });
      }
    };

    saveTokens();
  }, [location]);

  const handleNext = () => {
    // Clear onboarding flag and navigate to the next step
    localStorage.removeItem('onboarding');
    navigate('/billing');
  };

  const handleBackToCampaign = () => {
    // Clear onboarding flag and navigate to the dashboard
    localStorage.removeItem('onboarding');
    navigate('/campaigns/create');
  };

  const isOnboarding = localStorage.getItem('onboarding') === 'true';

  return (
    <Container maxWidth="md">
      {isOnboarding && (
        <OnboardingSteps activeStep={1} displayText={false} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          marginTop: 4,
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" component="h1">
          Welcome back!
        </Typography>
        {status.state === 'loading' && <Typography>Loading...</Typography>}
        {status.state === 'success' && <Typography sx={{ color: 'green' }}>{status.message}</Typography>}
        {status.state === 'error' && <Typography sx={{ color: 'red' }}>{status.message}</Typography>}
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          {isOnboarding ? (
            <>
              <Button
                type="button"
                variant="outlined"
                size="large"
                onClick={handleBackToCampaign}
                sx={{ flexGrow: 1, mr: 2 }}
              >
                Back: Campaigns
              </Button>
              <Button
                type="button"
                variant="contained"
                size="large"
                onClick={handleNext}
                sx={{ flexGrow: 1 }}
              >
                Next: Add Credits
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={() => navigate('/campaigns')}
              sx={{ mt: 2 }}
            >
              Use your new integration in your campaigns
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default GoogleOAuthRedirect;