import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  deleteCampaign,
  getCampaigns,
  getPaymentMethods,
  getThreadsByCampaignId,
  updateCampaign,
} from '../services/api/ApiService';

function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCampaignsAndThreads();
  }, []);

  const fetchCampaignsAndThreads = async () => {
    try {
      setLoading(true);
      const allCampaigns = await getCampaigns();
      const campaignsWithExtras = await Promise.all(
        allCampaigns.map(async (campaign) => {
          const threads = await getThreadsByCampaignId(campaign.id);
          return { ...campaign, threadCount: threads.length };
        }),
      );
      setCampaigns(campaignsWithExtras);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch campaigns and threads:', error);
      setLoading(false);
    }
  };

  const handleDeleteCampaign = async (campaignId) => {
    try {
      await deleteCampaign(campaignId);
      await fetchCampaignsAndThreads();
    } catch (error) {
      console.error('Failed to delete campaign:', error);
    }
  };

  const handleStatusToggle = async (campaignId) => {
    try {
      const campaign = campaigns.find((c) => c.id === campaignId);

      const data = await getPaymentMethods();
      const paymentMethods = data.data;


      // Check if there are card payment methods for "card" campaigns
      if (campaign.charge_on === 'card' && campaign.status !== 'live') {
        const hasCardMethod = paymentMethods.some(
          (method) => method.type === 'card',
        );
        if (!hasCardMethod) {
          alert(
            'Cannot activate this campaign. A card payment method is required to activate card-based campaigns.',
          );
          return;
        }
      }

      // Update campaign status
      await updateCampaign(campaignId, {
        status: campaign.status === 'live' ? 'inactive' : 'live',
      });
      setCampaigns((prevCampaigns) =>
        prevCampaigns.map((c) =>
          c.id === campaignId
            ? { ...c, status: c.status === 'live' ? 'inactive' : 'live' }
            : c,
        ),
      );
    } catch (error) {
      console.error('Failed to toggle campaign status:', error);
      alert('An error occurred while toggling the campaign status.');
    }
  };

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        Campaign Management
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Set up campaigns to incentivize your email recipients to reply faster.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 3 }}
        onClick={() => navigate('/campaigns/create')}
      >
        Create New Campaign
      </Button>
      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Campaign Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="center">
                Status
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="center">
                Threads
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="center">
                Charge On
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="center">
                Created At
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {campaigns.map((campaign) => (
                <TableRow key={campaign.id}>
                  <TableCell>
                    <Button
                      variant="text"
                      onClick={() => navigate(`/campaigns/${campaign.id}`)}
                      sx={{
                        textTransform: 'none',
                        fontSize: 16,
                        textAlign: 'left',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {campaign.name}
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={campaign.status === 'live'}
                      onChange={() => handleStatusToggle(campaign.id)}
                      color="primary"
                    />
                    {campaign.status === 'live' ? 'Active' : 'Inactive'}
                  </TableCell>
                  <TableCell align="center">{campaign.threadCount}</TableCell>
                  <TableCell align="center">
                    {campaign.charge_on === 'card' ? 'Card' : 'Credit'}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(campaign.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteCampaign(campaign.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Campaigns;