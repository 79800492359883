import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import EmailTable from './EmailTable';


export default function DraftsList({
                                     open,
                                     isLoading,
                                     onClose,
                                     drafts,
                                     onTrackEmail,
                                     refreshDrafts,
                                   }) {
  useEffect(() => {
    if (!open) {
      // Reset focus to a specific element or body
      document.activeElement.blur();
    }
  }, [open]);

  return (
    <>

      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>Available Draft Emails</DialogTitle>
          <Button onClick={refreshDrafts} sx={{ marginRight: 2 }}>Refresh</Button>
        </Box>
        <DialogContent>
          {isLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box> :
            <EmailTable
              emails={drafts}
              onRemove={onTrackEmail}
              type="drafts"
            />
          }
        </DialogContent>
      </Dialog>
    </>
  );
}