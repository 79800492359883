import React from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, Button } from '@mui/material';

const CheckoutForm = ({ onSavePaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log('Stripe has not loaded yet!');
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('Error:', error);
    } else {
      console.log('PaymentMethod:', paymentMethod);
      if (paymentMethod && onSavePaymentMethod) {
        onSavePaymentMethod(paymentMethod.id);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ my: 3, border: '1px solid #ccc', borderRadius: '4px', padding: '10px' }}>
        <CardElement id="payment-method" options={CARD_ELEMENT_OPTIONS} />
      </Box>
      <Button
        id="add-payment-method-btn"
        type="submit"
        variant="contained"
        color="primary"
        disabled={!stripe}
        fullWidth
      >
        Set Up Billing
      </Button>
    </form>
  );
};

export default CheckoutForm;
