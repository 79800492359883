import React from 'react';
import { Box, Button, CircularProgress, Container, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useAuth } from '../services/auth/AuthService'; // Adjust the import path as needed
import { deleteUserProfile } from '../services/api/ApiService'; // Adjust the import path as needed

function Profile() {
  const { userDetails, deleteUser } = useAuth(); // Destructure userDetails from useAuth
  const [loading, setLoading] = React.useState(false);

  const handleDeleteUser = async () => {
    setLoading(true);
    await deleteUserProfile(userDetails.id).then(() => {
      localStorage.removeItem('campaignData');
      deleteUser(userDetails.email);
      setLoading(false);
      window.location.href = '/login';
    }).catch((error) => {
        console.error('Failed to delete user profile', error);
        alert('Failed to delete user profile');
        setLoading(false);
      },
    );
  };

  return (
    <Container maxWidth="md">
      {loading ? (
        <TableBody>
          <TableRow>
            <TableCell colSpan={8} align="center">
              <CircularProgress />
            </TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginTop: 4,
          }}
        >
          <Typography variant="h4" component="h1">
            Profile Details
          </Typography>
          <Box>
            <Typography variant="h6">Email:</Typography>
            <Typography>{userDetails?.email || 'No email found'}</Typography>
          </Box>
          <Button id="delete-account-btn" onClick={() => handleDeleteUser()} variant="contained" color="error">
            Delete User
          </Button>
        </Box>
      )}
    </Container>
  );
}

export default Profile;
