import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Typography } from '@mui/material';
import { updateCampaign } from '../../services/api/ApiService';


const IncentiveMentionedPopup = ({ campaign, setCampaign }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [livePopupOpen, setLivePopupOpen] = useState(false);
  const [showCopyMsg, setShowCopyMsg] = useState(false);
  // Function to parse the incentive deadline (e.g. "1 hour", "2 hours")
  const parseIncentiveDeadline = (deadline) => {
    const match = deadline.match(/(\d+)\s*(hour|hours)/); // Matches "1 hour" or "2 hours"
    if (match) {
      const hours = parseInt(match[1], 10);  // Extracts the number
      return hours;
    }
    return 0;  // Default to 0 hours if not parsable
  };


  // Function to calculate the future time with the added hours
  const getFutureTime = (hoursToAdd) => {
    const now = new Date();
    now.setHours(now.getHours() + hoursToAdd);  // Adds the hours to current time

    // Format the future time to a readable format: "h:mm AM/PM"
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

    return formattedTime;
  };

  const hoursToAdd = parseIncentiveDeadline(campaign.incentive_deadline);
  const futureTime = getFutureTime(hoursToAdd);
  const exampleText = `We use Speedable to speed up email conversations. Simply reply by ${futureTime} to get a coffee on me or donate the value to charity.`;

  useEffect(() => {
    if (!popupOpen) {
      document.activeElement.blur();
    }
  }, [popupOpen]);

  useEffect(() => {
    if (!livePopupOpen) {
      document.activeElement.blur();
    }
  }, [livePopupOpen]);
  const handleCopyText = () => {
    navigator.clipboard.writeText(exampleText);
    setShowCopyMsg(true);
    setTimeout(() => {
      setShowCopyMsg(false);
      setPopupOpen(false);
    }, 750);
  };
  const handleIncentiveToggle = async (incentive_mentioned) => {
    if (campaign.status === 'live' && !incentive_mentioned) {
      return;
    }
    setCampaign({ ...campaign, incentive_mentioned: incentive_mentioned });
    updateCampaign(campaign.id, { incentive_mentioned: incentive_mentioned }).then(null);
  };
  return (
    <>
      <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
        <DialogTitle>Example Text</DialogTitle>
        <DialogContent>
          <Typography>{exampleText}</Typography>
          {showCopyMsg && <Typography sx={{ color: 'green' }}>Copied to clipboard!</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopyText} color="primary">
            Copy to Clipboard
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={livePopupOpen} onClose={() => setLivePopupOpen(false)}>
        <DialogTitle>Campaign Live</DialogTitle>
        <DialogContent>
          <Typography>You can now send your email drafts.</Typography>
          <Typography>If your recipients meet your deadline, we'll send them their rewards</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLivePopupOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Switch
        checked={campaign.incentive_mentioned || false}
        onChange={() => handleIncentiveToggle(!campaign.incentive_mentioned)}
        color="primary"
      />
      <Button
        variant="text"
        color="primary"
        onClick={() => setPopupOpen(true)}
      >
        Click for example
      </Button>
    </>
  );
};

export default IncentiveMentionedPopup;