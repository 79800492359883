import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { getGoogleRefreshIntegrationURL } from '../../../services/api/ApiService'; // Adjust the import path as needed

function GoogleOAuthRefreshRedirect() {
  const hasFetched = useRef(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const onboarding = searchParams.get('onboarding');

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;

    const fetchGoogleAuthUrl = async () => {
      try {
        if (onboarding) {
          localStorage.setItem('onboarding', 'true');
        }
        const response = await getGoogleRefreshIntegrationURL(email);
        window.location.href = response.auth_url;
      } catch (error) {
        console.error('Failed to fetch Google OAuth URL:', error);
      }
    };

    fetchGoogleAuthUrl();
  }, [email]);

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 4, alignItems: 'center' }}>
        <Typography variant="h4" component="h1">
          Redirecting to Google...
        </Typography>
        <CircularProgress />
      </Box>
    </Container>
  );
}

export default GoogleOAuthRefreshRedirect;