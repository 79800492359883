import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getUserByCognitoSub, getUserCredits } from '../api/ApiService';
import {
  confirmUser,
  deleteUser,
  requestResetPassword,
  resetPassword,
  signIn,
  signUp,
  userPool,
} from './cognitoService';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [userCredits, setUserCredits] = useState([]);
  const [onboarded, setOnboarded] = useState(false);
  const _defaultCurrency = 'GBP';

  useEffect(() => {
    const fetchSession = async () => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        setIsAuthenticated(false);
        setLoading(false); // Session check complete
        return;
      }

      cognitoUser.getSession(async (err, session) => {
        if (err || !session.isValid()) {
          setIsAuthenticated(false);
          setUserDetails(null);
          setLoading(false); // Session check complete
          return;
        }

        const jwtToken = session.getIdToken().getJwtToken();
        localStorage.setItem('jwtToken', jwtToken);
        const cognitoSub = session.idToken.payload.sub;

        try {
          const userDetails = await getUserByCognitoSub(cognitoSub);
          userDetails['email'] = localStorage.getItem('email').toLowerCase();
          const _userCredits = await getUserCredits(userDetails.id);
          setUserDetails(userDetails);
          setUserCredits(_userCredits);
          setIsAuthenticated(true);
        } catch (userError) {
          setUserDetails(null);
          setIsAuthenticated(false);
        } finally {
          setLoading(false); // Session check complete
        }
      });
    };

    fetchSession();
  }, []);

  const handleSignIn = async (email, password, location = '', company = '') => {
    email = email.toLowerCase();
    try {
      await signIn(email, password, location, company, setUserDetails, setUserCredits);
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
      setUserDetails(null);
      throw error;
    }
  };

  const handleSignUp = async (email, password) => {
    email = email.toLowerCase();
    try {
      await signUp(email, password, setUserDetails);
    } catch (error) {
      setUserDetails(null);
      throw error;
    }
  };

  const handleConfirmUser = async (email, confirmationCode) => {
    email = email.toLowerCase();
    try {
      await confirmUser(email, confirmationCode);
    } catch (error) {
      setUserDetails(null);
      throw error;
    }
  };
  const handleSignOut = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      localStorage.removeItem('jwtToken');
    }
    setIsAuthenticated(false);
    setUserDetails(null);
  };

  const handleDeleteUser = async (email) => {
    email = email.toLowerCase();
    try {
      await deleteUser(email);
      setIsAuthenticated(false);
      setUserDetails(null);
    } catch (error) {
      throw error;
    }
  };

  const handleRequestResetPassword = async (email) => {
    email = email.toLowerCase();
    try {
      await requestResetPassword(email);
    } catch (error) {
      throw error;
    }
  };

  const handleResetPassword = async (email, verificationCode, newPassword) => {
    email = email.toLowerCase();
    try {
      await resetPassword(email, verificationCode, newPassword);
    } catch (error) {
      throw error;
    }
  };

  const syncUserCredits = async () => {
    const _userCredits = await getUserCredits(userDetails.id);
    setUserCredits(_userCredits);
  };

  const syncIsOnboarded = async () => {
    const _user = await getUserByCognitoSub(userDetails.cognito_sub);
    setOnboarded(_user.is_onboarded);
  };

  const hasCredits = useMemo(() => {
    const _creditItem = userCredits.find((item) => item.currency === _defaultCurrency);
    const creditValue = _creditItem ? _creditItem.credit / Math.pow(10, _creditItem.currencyDecimals) : 0;
    return creditValue > 0;
  }, [userCredits]);

  const isOnboarded = useMemo(() => {
    return userDetails?.is_onboarded;
  }, [userDetails]);

  const credit = useMemo(() => {
    const _creditItem = userCredits.find((item) => item.currency === _defaultCurrency);
    const intl = Intl.NumberFormat('en-US', {
      currencyDisplay: 'symbol',
      style: 'currency',
      currency: _defaultCurrency,
    });
    const creditValue = _creditItem ? _creditItem.credit / Math.pow(10, _creditItem.currencyDecimals) : 0;
    return intl.format(creditValue);
  }, [userCredits]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userDetails,
        loading,
        credit,
        hasCredits,
        isOnboarded,
        requestResetPassword: handleRequestResetPassword,
        resetPassword: handleResetPassword,
        signIn: handleSignIn,
        signOut: handleSignOut,
        signUp: handleSignUp,
        deleteUser: handleDeleteUser,
        confirmUser: handleConfirmUser,
        syncIsOnboarded,
        syncUserCredits,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
