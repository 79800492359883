import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import {
  deletePaymentMethod,
  getPaymentMethods,
  postRedeemVoucher,
  savePaymentMethod,
  setDefaultPaymentMethod,
  updateCampaign,
} from '../services/api/ApiService';
import { Box, Button, Card, CardContent, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useAuth } from '../services/auth/AuthService';
import OnboardingSteps from '../components/Onboarding/OnboardingSteps';
import { updateOnboardingStatus } from '../utils';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const Billing = () => {
  const navigate = useNavigate();
  const [voucherCode, setVoucherCode] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [errorMsg, setErrorMessage] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [successMsg, setSuccessMessage] = useState(null);
  const [busy, setBusy] = useState(false);
  const [busyPaymentMethod, setBusyPaymentMethod] = useState(false);
  const { credit, syncUserCredits, isOnboarded, userDetails } = useAuth();
  const options = {
    clientSecret: process.env.STRIPE_SECRET_KEY,
  };

  const fetchPaymentMethods = async () => {
    try {
      const response = await getPaymentMethods();
      if (response.data) {
        setPaymentMethods(response.data);
      }
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
    const data = JSON.parse(localStorage.getItem('campaignData'));
    setCampaignId(data?.campaignId);
  }, []);

  const handleSavePaymentMethod = async (paymentMethodId) => {
    try {
      setBusyPaymentMethod(true);
      await savePaymentMethod(paymentMethodId);
      await fetchPaymentMethods(); // Refresh list after adding
      if (!isOnboarded) {
        await updateOnboardingStatus('billing_setup', 3, userDetails);
      }
      setSuccessMessage('Payment method added successfully');
    } catch (error) {
      console.error('Error saving payment method:', error);
      setErrorMessage('Failed to add payment method');
    } finally {
      setBusyPaymentMethod(false);
    }
  };

  const handleDeletePaymentMethod = async (paymentMethodId) => {
    try {
      const response = await deletePaymentMethod(paymentMethodId);
      setSuccessMessage(response.message);
      await fetchPaymentMethods(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting payment method:', error);
      setErrorMessage('Failed to delete payment method');
    }
  };

  const handleApplyVoucher = async (e) => {
    e.preventDefault();
    try {
      setBusy(true);
      if (!voucherCode) {
        setErrorMessage('Please enter a voucher code');
        return;
      }
      await postRedeemVoucher(voucherCode);
      syncUserCredits();
      setVoucherCode('');
      setSuccessMessage('Voucher redeemed successfully');
      if (!isOnboarded) {
        await updateOnboardingStatus('billing_setup', 3, userDetails);
      }
    } catch (err) {
      console.error('Error redeeming voucher:', err);
      setErrorMessage(err?.message ?? 'Unable to redeem voucher');
    } finally {
      setTimeout(() => {
        setSuccessMessage(null);
        setErrorMessage(null);
      }, 5000);
      setBusy(false);
    }
  };

  const handleNext = async () => {
    //   if there is a voucher, update campaign charge on to credits. if there is a payment metthod then update campaign charge on to card, if neither then show a warning message
    let campaignChargeOn;
    if (credit !== '£0.00') {
      campaignChargeOn = 'credits';
    } else if (paymentMethods.length > 0) {
      campaignChargeOn = 'card';
    } else {
      setErrorMessage('Please add a payment method or redeem a voucher to continue');
      return;
    }
    await updateCampaign(campaignId, { charge_on: campaignChargeOn });
    navigate(`/campaigns/${campaignId}`);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      {!isOnboarded &&
        <OnboardingSteps activeStep={2} displayText={false} />
      }
      {successMsg && (
        <Box
          sx={{
            my: 2,
            padding: 1.5,
            fontWeight: 'bold',
            fontSize: 14,
            color: 'green',
            backgroundColor: '#DFF2BF',
            borderRadius: 1,
          }}
        >
          {successMsg}
        </Box>
      )}
      {errorMsg && (
        <Box
          sx={{
            my: 2,
            padding: 1.5,
            fontWeight: 'bold',
            fontSize: 14,
            color: 'red',
            backgroundColor: '#FFCDD2',
            borderRadius: 1,
          }}
        >
          {errorMsg}
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Enter Voucher Code
              </Typography>
              <form onSubmit={handleApplyVoucher} noValidate>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="voucherCode"
                    label="Voucher Code"
                    name="voucherCode"
                    autoFocus
                    disabled={busy}
                    value={voucherCode}
                    onChange={(e) => setVoucherCode(e.target.value?.toUpperCase())}
                    sx={{ zIndex: 0 }}
                  />

                  <Button
                    disabled={busy}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {busy ? <CircularProgress size={24} color="inherit" /> : 'Apply'}
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Add New Payment Method
              </Typography>
              <Elements stripe={stripePromise} options={options}>
                {busyPaymentMethod ? <CircularProgress size={24} color="inherit" /> :
                  <CheckoutForm onSavePaymentMethod={handleSavePaymentMethod} />
                }
              </Elements>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ marginTop: 3 }}>
        <Grid item xs={12}>
          {credit && (
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Credits
                </Typography>
                <Typography variant="body1">Available: {credit}</Typography>
              </CardContent>
            </Card>
          )}

          {paymentMethods.length > 0 && (
            paymentMethods.map((pm) => (
              <Card key={pm.id} sx={{ marginBottom: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Card: **** **** **** {pm.card.last4}
                  </Typography>
                  <Typography variant="body1">Type: {pm.card.display_brand || pm.card.brand}</Typography>
                  <Typography variant="body2">
                    Expiry: {pm.card.exp_month}/{pm.card.exp_year}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, marginTop: 2 }}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeletePaymentMethod(pm.id)}
                      sx={{ flexGrow: 1 }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setDefaultPaymentMethod(pm.id)}
                      disabled={paymentMethods.length === 1}
                      sx={{
                        flexGrow: 1,
                        backgroundColor: paymentMethods.length === 1 ? 'grey' : 'primary.main',
                        ':hover': {
                          backgroundColor: paymentMethods.length === 1 ? 'grey' : 'primary.dark',
                        },
                      }}
                    >
                      Set as Default
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            ))
          )}
        </Grid>
      </Grid>

      {!isOnboarded &&
        <Box sx={{ display: 'flex', gap: 2, marginTop: 3 }}>
          <Button
            type="button"
            variant="outlined"
            size="large"
            onClick={() => navigate('/integrations/gmail')}
            sx={{ flexGrow: 1, padding: '12px 24px' }}
          >
            Back: Gmail Integration
          </Button>
          <Button
            type="button"
            variant="contained"
            size="large"
            onClick={() => handleNext()}
            sx={{ flexGrow: 1, padding: '12px 24px' }}
          >
            Next: Track Emails
          </Button>
        </Box>
      }
    </Box>
  );
};

export default Billing;