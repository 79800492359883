import React from 'react';
import { TextField } from '@mui/material';

const CampaignNameInput = ({ value, onChange }) => {
  return (
    <TextField
      label="Campaign Name"
      variant="outlined"
      fullWidth
      value={value}
      onChange={onChange}
      required
    />
  );
};

export default CampaignNameInput;