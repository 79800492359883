import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { updateUser } from '../../services/api/ApiService';
import { useAuth } from '../../services/auth/AuthService';

const steps = [
  {
    label: 'Create Campaign',
    description: 'Set up your first email campaign with incentives like coffee or tree planting rewards.',
  },
  {
    label: 'Connect Gmail',
    description: 'Link your Gmail account to track responses and automate rewards.',
  },
  {
    label: 'Add Card',
    description: 'Add card or voucher code for incentives',
  },
  {
    label: 'Attach Drafts',
    description: 'Select email drafts from your inbox to add incentives.',
  },
  {
    label: 'Activate Campaign',
    description: 'Turn on your campaign to start rewarding recipients for replying to your emails.',
  },
];

const OnboardingSteps = ({ activeStep = 0, displayText = true }) => {
  const { userDetails, syncIsOnboarded } = useAuth();
  const navigate = useNavigate();

  const handleCancel = async () => {
    localStorage.removeItem('campaignData');  // Clear campaign data in local storage
    await updateUser(userDetails.id, {
      is_onboarded: true,
      onboarding_progress: userDetails.onboarding_progress,
    }).then(null);
    await syncIsOnboarded();
    window.location.href = '/campaigns';
  };

  return (
    <Box sx={{ width: '100%', mb: 4 }}>

      {/* Stepper for onboarding steps */}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {step.label}
              </Typography>
              {displayText && (
                <Typography variant="caption" sx={{ display: 'block', textAlign: 'center', mt: 1 }}>
                  {step.description}
                </Typography>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ textAlign: 'right', mb: 2 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          sx={{
            fontSize: '0.875rem',
            textTransform: 'none',
            padding: '8px 16px',
            my: 2,
            mr: 2,
            boxShadow: 'none',
            display: 'inline-block',
            backgroundColor: 'secondary.main',
            '&:hover': {
              backgroundColor: 'secondary.dark',
            },
          }}
        >
          Cancel Onboarding
        </Button>
      </Box>
    </Box>
  );
};

export default OnboardingSteps;