import { updateUser } from './services/api/ApiService';

const updateOnboardingStatus = async (step_name, index, userDetails) => {
  const onboardingProgress = userDetails.onboarding_progress.steps;
  const currentStepIndex = onboardingProgress.findIndex((step) => step.name === step_name);
  if (currentStepIndex === -1) {
    onboardingProgress.push({ name: step_name, completed: true, index: index });
  } else {
    onboardingProgress[currentStepIndex].completed = true;
  }
  await updateUser(userDetails.id, { onboarding_progress: { steps: onboardingProgress } });
};

export { updateOnboardingStatus };
