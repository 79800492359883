import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Timer } from '@mui/icons-material';

const DeadlineInput = ({ value, onChange }) => {
  return (
    <TextField
      label="Incentive Deadline (hours)"
      type="number"
      fullWidth
      value={value}
      onChange={onChange}
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Timer />
            <span style={{ marginLeft: '8px' }}>hours</span>
          </InputAdornment>
        ),
        inputProps: { min: 1, max: 720 }, // Max 30 days in hours
      }}
      helperText="Enter the number of hours until the incentive expires (1-720 hours)"
    />
  );
};

export default DeadlineInput;